import React, { Component } from "react";
import { connect } from "react-redux";
import Organisasi from "./Organisasi";
import bagan_organisasi from "../../img/bagan_organisasi.jpeg";
class Pengurus extends Component {
  render() {
    return (
      <main className="main_container">
        <Organisasi />
        <h1>Pengurus</h1>
        <img alt="PAJ" src={bagan_organisasi} style={{ width: "100%" }} />
      </main>
    );
  }
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(Pengurus);
