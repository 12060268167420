//Library
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
//Image
import logo from "../../img/logo.png";
class Navigation extends Component {
  onClickMenu = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  render() {
    const pathname = this.props.auth.pathname;
    const menu_list = [
      {
        name: "Organisasi",
        link: "/organisasi",
        main: "/organisasi",
      },
      {
        name: "Networking",
        link: "/networking",
        main: "/networking",
      },
      {
        name: "Media",
        link: "/media",
        main: "/media",
      },
    ];
    let nav = (
      <main className="nav noselect">
        <main className="nav_limiter">
          <section className="nav_header">
            <div className="nav_title">
              <Link to="/">
                <img src={logo} width="50" alt="paj" />
              </Link>
            </div>
          </section>
          <section className="nav_links">
            {menu_list.map((menu, idx) => {
              let id = "not_active";
              if (pathname.includes(menu.main)) {
                id = "active";
              }
              return (
                <Link
                  key={idx}
                  to={`${menu.link}`}
                  id={id}
                  onClick={this.onClickMenu}
                >
                  {menu.name}
                </Link>
              );
            })}
          </section>
        </main>
      </main>
    );
    return nav;
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(Navigation);
