import React, { Component } from "react";
import { connect } from "react-redux";
import Networking from "./Networking";
import { getPerusahaan } from "../../actions/authActions";
class Perusahaan extends Component {
  componentDidMount() {
    this.props.getPerusahaan();
  }
  render() {
    const { perusahaan } = this.props.auth;
    let features = perusahaan?.geojson?.features
      ? perusahaan.geojson.features
      : [];
    features = features.filter((f) => f?.properties?.Verifikasi === "yes");
    return (
      <main className="main_container" style={{ textAlign: "center" }}>
        <Networking />
        <h1>Perusahaan Terhubung</h1>
        {features.map((u, idx) => {
          const { properties } = u;
          const {
            Foto_ProdukUsaha,
            Nama_Perusahaan,
            Jenis_Usaha,
            Website,
            No_TelpWA,
            Lokasi_Usaha,
            Company_Profile,
            Rotate,
          } = properties;
          let link;
          if (Website.includes("http")) {
            link = Website;
          } else {
            link = `https://${Website}`;
          }
          let link_2;
          if (Company_Profile?.includes("http")) {
            link_2 = Company_Profile;
          } else {
            link_2 = `https://${Company_Profile}`;
          }
          const link_content = (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="button_social"
            >
              Website
            </a>
          );
          const link_content_2 = Company_Profile && (
            <a
              href={link_2}
              target="_blank"
              rel="noopener noreferrer"
              className="button_social"
            >
              Compro
            </a>
          );
          return (
            <main
              key={idx}
              style={{
                width: "300px",
                display: "inline-block",
                verticalAlign: "top",
                textAlign: "left",
                boxShadow: "0px 0px 8px #dedede",
                borderRadius: "15px",
                padding: "10px",
                margin: "10PX",
              }}
            >
              {/* <img
                alt="Alumni"
                src={Foto_Potrait_PRO}
                style={{ width: "90px", transform: "rotate(90deg)" }}
              /> */}
              <section style={{ display: "inline-block", marginRight: "10px" }}>
                <section
                  style={{
                    height: "120px",
                    width: "90px",
                    marginBottom: "5px",
                  }}
                >
                  {Rotate === "yes" ? (
                    <div
                      style={{
                        transform: "rotate(90deg) translate(15px, 15px)",
                        backgroundImage: `url(${Foto_ProdukUsaha})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: "90px",
                        width: "120px",
                        display: "inline-block",
                        borderRadius: "10px",
                        top: "-50%",
                        left: "-50%",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        backgroundImage: `url(${Foto_ProdukUsaha})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: "120px",
                        width: "90px",
                        display: "inline-block",
                        borderRadius: "10px",
                      }}
                    />
                  )}
                </section>
                {link_content}
                {link_content_2}
              </section>
              <section
                style={{
                  display: "inline-block",
                  width: "150px",
                  verticalAlign: "top",
                }}
              >
                <h3
                  style={{
                    height: "50px",
                    overflowX: "hidden",
                    overflowY: "hidden",
                  }}
                >
                  {Nama_Perusahaan}
                </h3>
                <main style={{ marginBottom: "5px" }}>
                  <section className="text_inferior">Jenis_Usaha</section>
                  <section className="text_isi">{Jenis_Usaha}</section>
                </main>
                <main style={{ marginBottom: "5px" }}>
                  <section className="text_inferior">No_TelpWA</section>
                  <section className="text_isi">
                    {No_TelpWA ? No_TelpWA : "-"}
                  </section>
                </main>
                <section
                  className="text_inferior"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {Lokasi_Usaha}
                </section>
              </section>
            </main>
          );
        })}
        <br />
        <a
          className="button_standard"
          href="https://form.mapid.io/610d519d95f42241ab1842cc"
          target="_blank"
          rel="noopener noreferrer"
        >
          Tambahkan perusahaan kamu
        </a>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  getPerusahaan,
})(Perusahaan);
