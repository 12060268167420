import axios from "axios";
// const SERVER_URL = "http://localhost:4000";
const SERVER_URL = "https://server.mapid.io";

export const setPathname = (pathname) => {
  return {
    type: "SET_PATHNAME",
    payload: pathname,
  };
};

export const getYoutube = () => async (dispatch) => {
  try {
    const res = await axios.get(
      "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=PLnhgZOGm68cUxetmNUey-w1WTmnz2kHfz&key=AIzaSyAxt7AJS-XoYnO7JQSe3QLZZdObw0AAZsU"
    );
    dispatch({
      type: "GET_YOUTUBE",
      payload: res.data.items,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getAlumni = () => async (dispatch) => {
  try {
    const res = await axios.get(SERVER_URL + "/layers_new/get_alumni_paj");
    dispatch({
      type: "GET_ALUMNI",
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getPerusahaan = () => async (dispatch) => {
  try {
    const res = await axios.get(SERVER_URL + "/layers_new/get_perusahaan_paj");
    dispatch({
      type: "GET_PERUSAHAAN",
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
  }
};

// export const getInstagram = () => async (dispatch) => {
//   try {
//     const res = await axios.get(
//       "https://www.instagram.com/paj.official_/?__a=1"
//     );
//     // dispatch({
//     //   type: "GET_INSTAGRAM",
//     //   payload: res.data,
//     // });
//     console.log(res.data);
//   } catch (e) {
//     console.log("error_instagram=", e);
//   }
// };

// export const getInstagram = () => (dispatch) => {
//   fetchJsonp("https://www.instagram.com/paj.official_/?__a=1")
//     .then(function (response) {
//       console.log(response);
//       return response.json();
//     })
//     .then(function (json) {
//       console.log(json);
//       console.log("parsed json", json);
//     })
//     .catch(function (ex) {
//       console.log("parsing failed", ex);
//     });
// };

export const getInstagram = () => async (dispatch) => {
  try {
    let response = await fetch(
      "https://www.instagram.com/paj.official_/?__a=1",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "API-Key": "secret",
        },
      }
    );
    console.log(response);
  } catch (error) {
    console.log(error);
  }
};
