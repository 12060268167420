import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import banner_networking from "../../img/banner_networking.png";
class Networking extends Component {
  onClickMenu = () => {
    window.scrollTo({
      top: 525,
      behavior: "smooth",
    });
  };
  render() {
    const pathname = this.props.auth.pathname;
    const menu_list = [
      {
        name: "Alumni",
        link: "/networking/alumni",
      },
      {
        name: "Perusahaan Alumni",
        link: "/networking/perusahaan",
      },
    
    ];
    return (
      <main>
        <img
          src={banner_networking}
          alt="paj event"
          style={{ width: "100%" }}
        />
        <section className="menu_container noselect">
          {menu_list.map((menu, idx) => {
            let id = "not_active";
            if (menu.link === pathname) {
              id = "active";
            }
            return (
              <Link
                className="menu_item"
                key={idx}
                to={`${menu.link}`}
                id={id}
                onClick={this.onClickMenu}
              >
                {menu.name}
              </Link>
            );
          })}
        </section>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(Networking);
