import React, { Component } from "react";
import { connect } from "react-redux";
import Networking from "./Networking";
import { getAlumni } from "../../actions/authActions";
class Alumni extends Component {
  componentDidMount() {
    this.props.getAlumni();
  }
  render() {
    const { alumni } = this.props.auth;
    let features = alumni?.geojson?.features ? alumni.geojson.features : [];
    features = features.filter((f) => f?.properties?.Verifikasi === "yes");
    return (
      <main className="main_container" style={{ textAlign: "center" }}>
        <Networking />
        <h1>Alumni</h1>
        {features.map((u, idx) => {
          const { properties } = u;
          const {
            Foto_Potrait_PRO,
            Nama_Lengkap,
            Profesi,
            Almamater,
            Domisili,
            Sosial_Media,
            Bio,
            Rotate,
          } = properties;
          let link;
          if (Sosial_Media.includes("http")) {
            link = Sosial_Media;
          } else {
            link = `https://${Sosial_Media}`;
          }
          const link_content = (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="button_social"
            >
              Sosial Media
            </a>
          );
          return (
            <main
              key={idx}
              style={{
                width: "300px",
                display: "inline-block",
                verticalAlign: "top",
                textAlign: "left",
                boxShadow: "0px 0px 8px #dedede",
                borderRadius: "15px",
                padding: "10px",
                margin: "10PX",
              }}
            >
              {/* <img
                alt="Alumni"
                src={Foto_Potrait_PRO}
                style={{ width: "90px", transform: "rotate(90deg)" }}
              /> */}
              <section style={{ display: "inline-block", marginRight: "10px" }}>
                <section
                  style={{
                    height: "120px",
                    width: "90px",
                    marginBottom: "5px",
                  }}
                >
                  {Rotate === "yes" ? (
                    <div
                      style={{
                        transform: "rotate(90deg) translate(15px, 15px)",
                        backgroundImage: `url(${Foto_Potrait_PRO})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: "90px",
                        width: "120px",
                        display: "inline-block",
                        borderRadius: "10px",
                        top: "-50%",
                        left: "-50%",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        backgroundImage: `url(${Foto_Potrait_PRO})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: "120px",
                        width: "90px",
                        display: "inline-block",
                        borderRadius: "10px",
                      }}
                    />
                  )}
                </section>
                {link_content}
              </section>
              <section
                style={{
                  display: "inline-block",
                  width: "150px",
                  verticalAlign: "top",
                }}
              >
                <h3
                  style={{
                    height: "50px",
                    overflowX: "hidden",
                    overflowY: "hidden",
                  }}
                >
                  {Nama_Lengkap}
                </h3>
                <main style={{ marginBottom: "5px" }}>
                  <section className="text_inferior">Profesi</section>
                  <section className="text_isi">{Profesi}</section>
                </main>
                <main style={{ marginBottom: "5px" }}>
                  <section className="text_inferior">Almamater</section>
                  <section className="text_isi">{Almamater}</section>
                </main>
                <main style={{ marginBottom: "5px" }}>
                  <section className="text_inferior">Domisili</section>
                  <section className="text_isi">{Domisili}</section>
                </main>
                <section
                  className="text_inferior"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {Bio}
                </section>
              </section>
            </main>
          );
        })}
        <br />
        <a
          className="button_standard"
          href="https://form.mapid.io/610d519295f42241ab1842c2"
          target="_blank"
          rel="noopener noreferrer"
        >
          Tambahkan diri kamu di daftar alumni
        </a>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { getAlumni })(Alumni);
