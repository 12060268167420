import React, { Component } from "react";
import { Route, Router } from "react-router-dom";
import { Provider } from "react-redux";
import { Link } from "react-router-dom";
import "./App.css";
import "./Org.css";
import logo from "./img/logo.png";
import youtube from "./img/youtube.png";
import facebook from "./img/facebook.png";
import instagram from "./img/instagram.png";
//Home
import Navigation from "./components/Home/Navigation";
//Organisasi
import Sejarah from "./components/Organisasi/Sejarah";
import Pengurus from "./components/Organisasi/Pengurus";
import Kegiatan from "./components/Organisasi/Kegiatan";
//Networking
import Alumni from "./components/Networking/Alumni";
import Funding from "./components/Networking/Funding";
import Perusahaan from "./components/Networking/Perusahaan";
//Berita
import PajTV from "./components/Berita/PajTV";
//common
import store from "./store";
import history from "./actions/history";
import { setPathname } from "./actions/authActions";
class App extends Component {
  componentDidMount() {
    store.dispatch(setPathname(window.location.pathname));
  }
  componentWillMount() {
    this.unlisten = history.listen((location, action) => {
      store.dispatch(setPathname(location.pathname));
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }
  render() {
    const array_medsos = [
      {
        name: "Instagram",
        src: instagram,
        url: "https://www.instagram.com/paj.official_/?hl=de",
      },
      {
        name: "Facebook",
        src: facebook,
        url: "https://www.facebook.com/groups/paj.indonesia/",
      },
      {
        name: "YouTube",
        src: youtube,
        url: "https://www.youtube.com/channel/UCT_fBs9YJWQj-9hMX6rUvig",
      },
    ];
    return (
      <Provider store={store}>
        <Router history={history}>
          <div className="App">
            <Navigation />
            <Route exact path="/" component={Sejarah} />
            {/* Organisasi */}
            <Route exact path="/organisasi" component={Sejarah} />
            <Route exact path="/organisasi/sejarah" component={Sejarah} />
            <Route exact path="/organisasi/pengurus" component={Pengurus} />
            <Route exact path="/organisasi/kegiatan" component={Kegiatan} />
            {/* Networking */}
            <Route exact path="/networking" component={Alumni} />
            <Route exact path="/networking/alumni" component={Alumni} />
            <Route exact path="/networking/funding" component={Funding} />
            <Route exact path="/networking/perusahaan" component={Perusahaan} />
            {/* Berita */}
            <Route exact path="/media" component={PajTV} />
            <Route exact path="/media/paj_tv" component={PajTV} />
            <div className="footer">
              <div className="footer_limiter">
                <img
                  src={logo}
                  width="150"
                  alt="paj"
                  style={{ display: "inline-block", verticalAlign: "top" }}
                />
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "top",
                    marginTop: "30px",
                    marginLeft: "50px",
                  }}
                >
                  <Link style={{ color: "white" }} to="/organisasi">
                    Organisasi
                  </Link>
                  <br /> <br />
                  <Link style={{ color: "white" }} to="/networking">
                    Networking
                  </Link>
                  <br /> <br />
                  <Link style={{ color: "white" }} to="/media">
                    Media
                  </Link>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "top",
                    marginTop: "25px",
                    marginLeft: "50px",
                    whiteSpace: "pre-wrap",
                    lineHeight: "30px",
                  }}
                >
                  {`Gedung ESL
Jl. Latuharhary, S.H. No 19
Menteng, 10310
Jakarta`}
                </div>
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "top",
                    marginTop: "30px",
                    marginLeft: "50px",
                  }}
                >
                  {array_medsos.map((m, idx) => {
                    return (
                      <a
                        key={idx}
                        href={m.url}
                        target="_blank"
                        rel="noreferrer"
                        style={{ marginRight: "10px" }}
                      >
                        <img src={m.src} alt={m.name} width="30" />
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
