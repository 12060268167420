import React, { Component } from "react";
import { connect } from "react-redux";
import Organisasi from "./Organisasi";
class Sejarah extends Component {
  render() {
    const timeline_list = [
      {
        time: "1991",
        title: "Dibentuk oleh BJ. Habibie",
        desc: "Perhimpunan Alumni Jerman didirikan oleh Alm.Prof. DR(HC). Ing. Dr.Sc. Mult. Bacharuddin Jusuf Habibie atau yang lebih dikenal dengan B.J Habibie pada tahun 1991.",
      },
      {
        time: "1991",
        title: "Masa Kepemimpinan Ir. H. Soni Harsono ",
        desc: "Ir. H. Soni Harsono (lahir di Majenang, Cilacap, Jawa Tengah, 1 Mei 1930 – meninggal di Jakarta, 22 Desember 2011 pada umur 81 tahun) adalah mantan Menteri Negara Agraria /Kepala Badan Pertanahan Nasional periode 1993-1998, pada masa Kabinet Pembangunan VI.",
      },
      {
        time: "1999",
        title: "Reza Tadjoedin",
        desc: "",
      },
      {
        time: "2009",
        title: "Suchjar Effendi",
        desc: "",
      },
      {
        time: "2011",
        title: "Dr.Ing. Ilham Akbar Habibie, Dipl.Ing., M.B.A.",
        desc: "Dr.Ing. Ilham Akbar Habibie, Dipl.Ing., M.B.A. (lahir di Aachen, Jerman Barat, 16 Mei 1963; umur 58 tahun) adalah pakar penerbangan berdarah Gorontalo dari Indonesia.[2] Keluarga besar Habibie berasal dari Habilah, sebuah kecamatan di Provinsi Gorontalo.[3][4] Ilham adalah anak pertama dari pasangan Hasri Ainun Habibie dan B. J. Habibie, Presiden Indonesia periode 1998–1999.[5]",
      },
      {
        time: "2014",
        title: "Henry Pascal Tampubolon (alm) & Alexander Sukanta",
        desc: "",
      },
      {
        time: "2017",
        title: "Olfriady Letunggamu, M.Sc.",
        desc: "Olfriady Letunggamu, M.Sc. adalah pengusaha asal Indonesia yang besar di Berlin Jerman. Ia menjabat sebagai Ketua Umum Perhimpunan Alumni Jerman, periode 2017 hingga 2020[1][2] dan juga Ketua Komite Eropa Rusia Kamar Dagang dan Industri Indonesia (KADIN) serta sebagai Wakil Ketua Umum KADIN bidang Ekonomi dan Industri Kreatif.[3][4]Osco mempimpin perusahaan engineering dan tambang dibawah naungan Prima Nusantara Group.",
      },
      {
        time: "2020",
        title: "Ir. H. Vidi Galenso Syarief",
        desc: "Ir. H. Vidi Galenso Syarief adalah saudara laki-laki Elza Syarief. Ia mengenyam pendidikan di Berlin dan dia sekarang Advokat yang berfokus pada Hukum dari FHP. di Kantor Hukum Elza Syarief dan VGS & Partners",
      },
    ];
    return (
      <main className="main_container">
        <Organisasi />
        <h1>Sejarah</h1>
        <h2>Sejarah Berdirinya Perhimpunan Alumni Jerman</h2>
        <br></br>
        PAJ sebagai perhimpunan alumni Jerman serta lembaga organisasi massa
        berstatus Badan Hukum, didirikan berdasarkan Akte Notaris Mohamad Said
        Tadjoedin Nomor 8 tanggal 2 Maret 1991, dengan Anggaran Dasar dan
        Anggaran Rumah Tangga terdaftar di Departemen Dalam Negeri dan
        Pengadilan Negeri, serta disahkan Departemen Kehakiman dan diumumkan
        dalam Lembar Negara Republik Indonesia
        <br></br>
        <br />
        <h2>Visi</h2>
        <br></br>
        Menjadikan Perhimpunan Alumni Jerman (PAJ) sebagai wadah penyaluran
        aspirasi para anggotanya dan memberikan kontribusi serta nilai tambah
        kepada sesama Alumni, lingkungan sekitar, bangsa dan negara.
        <br></br>
        <br />
        <h2>Misi</h2>
        <br></br>
        <li>
          Mempersatukan, mempererat dan mewujudkan persaudaraan antar Alumni
        </li>
        <li>Menciptakan wadah aspirasi Alumni</li>
        <li>Menyinergikan potensi Alumni</li>
        <li>Menciptakan iklim usaha di kalangan Alumni</li>
        <li>
          Meningkatkan hubungan yang baik dengan Instansi Pemerintah Jerman di
          Indonesia dan Instansi Pemerintah di Jerman serta Lembaga Swasta kedua
          negara
        </li>
        <li>
          Memfasilitasi dan memberikan dukungan bagi mahasiswa Indonesia yang
          baru lulus maupun yang belum lulus untuk dapat bekerja atau
          melaksanakan program praktikum / magang
        </li>
        <div className="timeline">
          <ul>
            {timeline_list.map(({ time, title, desc }, idx) => {
              return (
                <li key={idx}>
                  <div className="content" key={idx}>
                    <h3>{title}</h3>
                    <p>{desc}</p>
                  </div>
                  <div className="time">
                    <h4>{time}</h4>
                  </div>
                </li>
              );
            })}
            <div style={{ clear: "both" }}></div>
          </ul>
        </div>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(Sejarah);
