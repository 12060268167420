import React, { Component } from "react";
import { connect } from "react-redux";
import Networking from "./Networking";
class Funding extends Component {
  render() {
    return (
      <main className="main_container">
        <Networking />
        <h1>Funding & Invest</h1>
        <div
          style={{
            height: "calc(( 100vh - 200px ))",
            width: "100%",
            backgroundColor: "teal",
            fontSize: "20px",
            color: "#fff",
          }}
        >
          Content Funding & Invest
        </div>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(Funding);
