import React, { Component } from "react";
import { connect } from "react-redux";
import Organisasi from "./Organisasi";
class Kegiatan extends Component {
  render() {
    return (
      <main className="main_container">
        <Organisasi />
        <h1>Kegiatan</h1>
        <iframe
          src="https://doc.mapid.io/blog/mapping_covid19_outbreak_with_geo_mapid"
          style={{ height: "calc(( 100vh - 200px ))", width: "100%" }}
          title="Kegiatan"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </main>
    );
  }
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(Kegiatan);
