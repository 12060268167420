import React, { Component } from "react";
import { connect } from "react-redux";
import Berita from "./Berita";
import { getYoutube } from "../../actions/authActions";
class PajTV extends Component {
  componentDidMount() {
    this.props.getYoutube();
  }
  render() {
    //IGQVJYZA2VUaDNEUElQWGI0aUVReXZAKT1hCTWpVUFlkY2M4QzYwTmM2UUdJaEdadWtMLWZA3aDBFWnlLVm5IUFlKRVExTEstckVXeENfXzJvRFhIcU5JVWpDUlFKTTZAPUGpaOS1YenBqc1lseldnYWJpSwZDZD
    const { youtube } = this.props.auth;
    const youtube_content = youtube.map((e, idx) => {
      const { videoId } = e.snippet.resourceId;
      const url = e.snippet.thumbnails.medium?.url;
      return (
        url && (
          <main
            key={idx}
            style={{
              width: "256px",
              height: "233px",
              display: "inline-block",
              margin: "10px",
              verticalAlign: "top",
              cursor: "pointer",
            }}
          >
            <iframe
              width="256"
              height="144"
              src={`https://www.youtube.com/embed/${videoId}`}
              srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${videoId}?autoplay=1><img src="${url}" alt='${e.snippet.title}'><span>▶</span></a>`}
              allowFullScreen
              title={e.snippet.title}
              frameBorder="0"
              allow="accelerometer;  clipboard-write; encrypted-media; gyroscope"
            ></iframe>
            <section className="text_title">{e.snippet.title}</section>
          </main>
        )
      );
    });

    return (
      <main className="main_container">
        <Berita />
        <h1>Media Organisasi</h1>
        <section style={{ textAlign: "center" }}>{youtube_content}</section>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { getYoutube })(PajTV);
