import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import banner_organisasi from "../../img/banner_organisasi.jpeg";
class Organisasi extends Component {
  state = {
    height: window.innerHeight,
  };
  onClickMenu = () => {
    //824 -- 220
    //794 -- 515
    //561 -- 500

    window.scrollTo({
      top: 500,
      behavior: "smooth",
    });
  };
  render() {
    const pathname = this.props.auth.pathname;
    const menu_list = [
      {
        name: "Sejarah",
        link: "/organisasi/sejarah",
      },
      {
        name: "Pengurus",
        link: "/organisasi/pengurus",
      },
    ];
    return (
      <main>
        <img
          src={banner_organisasi}
          alt="paj event"
          style={{ width: "100%" }}
        />
        <section className="menu_container noselect">
          {menu_list.map((menu, idx) => {
            let id = "not_active";
            if (menu.link === pathname) {
              id = "active";
            }
            return (
              <Link
                className="menu_item"
                key={idx}
                to={`${menu.link}`}
                id={id}
                onClick={this.onClickMenu}
              >
                {menu.name}
              </Link>
            );
          })}
        </section>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(Organisasi);
