import React, { Component } from "react";
import { connect } from "react-redux";
import banner_berita from "../../img/banner_berita.png";
class Berita extends Component {
  onClickMenu = () => {
    window.scrollTo({
      top: 525,
      behavior: "smooth",
    });
  };
  render() {
    return (
      <main>
        <img src={banner_berita} alt="paj event" style={{ width: "100%" }} />
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(Berita);
