const initialState = {
  pathname: "/",
  youtube: [],
  instagram: "",
  alumni: {},
  perusahaan: {},
};
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_PATHNAME":
      return {
        ...state,
        pathname: action.payload,
      };
    case "GET_YOUTUBE":
      return {
        ...state,
        youtube: action.payload,
      };
    case "GET_INSTAGRAM":
      return {
        ...state,
        instagram: action.payload,
      };
    case "GET_ALUMNI":
      return {
        ...state,
        alumni: action.payload,
      };
    case "GET_PERUSAHAAN":
      return {
        ...state,
        perusahaan: action.payload,
      };
    default:
      return state;
  }
}
